import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import { Vimeo } from "@swizec/gatsby-theme-course-platform";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">

    <h2 {...{
      "id": "how-to-approach-react-for-data-visualization"
    }}>{`How to approach React for Data Visualization`}</h2>
    <Vimeo id={424598481} mdxType="Vimeo" />
    <p>{`I recommend starting with an idea, a problem. Something you want to build. A
dataset you want to play with. A feature you want to add to your app.`}</p>
    <p>{`That makes it easier. Helps focus your brain. Notice things in this course that
help `}<em parentName="p">{`you`}</em>{` specifically.`}</p>
    <p>{`From there, I think you should see how much time you've got. The course is
designed so you can go over the important stuff in a couple hours. Watch a
video, read some text, try some code. See how it feels.`}</p>
    <p>{`If the best way is a daily practice, 20 minutes after work, 5 minutes on the
pooper, do that. If you're more of a sit down and cram it out kind of person, a
nice Sunday afternoon with some coffee will do just fine.`}</p>
    <p>{`But don't make it a chore. We're here to have fun 😃`}</p>
    <p>{`Two suggestions ✌️:`}</p>
    <ol>
      <li parentName="ol"><em parentName="li">{`Try`}</em>{` the example code. Don’t just copy-paste; type it and execute it.
Execute frequently. If something doesn’t fit together, look at the linked
Github repositories or solved CodeSandboxes. Each project has one.`}</li>
      <li parentName="ol">{`If you know something, skip that section. You’re awesome. :heart:`}</li>
    </ol>
    <p>{`React + D3 is based on code samples. They look like this:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-javascript"
      }}>{`let foo = "bar"
foo += "more stuff"
`}</code></pre>
    <p>{`Each code sample starts with a commented out file path. That's the file you're
editing. Like this:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-javascript"
      }}>{`// ./src/App.js

class App ...
`}</code></pre>
    <p>{`Commands that you should run in the terminal start with an `}<inlineCode parentName="p">{`$`}</inlineCode>{` symbol, like
this:`}</p>
    <pre><code parentName="pre" {...{}}>{`$ npm start
`}</code></pre>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      